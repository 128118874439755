<template>
  <div class="popular-products" v-if="popularProducts.length">
    <div class="popular-products__container _container">
      <div class="popular-products__top">
        <h2 class="popular-products__title main-title">{{ $t('popular') }}</h2>
        <div class="popular-products__navigation">
          <div class="popular-products__right navigation-next">
            <span><img src="../../assets/icons/swiper/arrow.svg" alt=""/></span>
          </div>
          <div class="popular-products__left navigation-prev">
            <span><img src="../../assets/icons/swiper/arrow.svg" alt=""/></span>
          </div>
        </div>
      </div>

      <swiper :modules="modules" :breakpoints="breakpoints" :slides-per-view="5" :space-between="20"
              :speed="650" :navigation="navigation"
              class="popular-products__swiper">
        <swiper-slide v-for="(product, id) in popularProducts" :key="id">
          <ProductItem @change="fetchHomePage" :product="product"></ProductItem>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from "swiper/vue/swiper-vue.js";
import ProductItem from "@/components/ProductItem";
import SwiperCore, {Navigation} from "swiper";
import {mapActions, mapState} from "vuex";

SwiperCore.use([Navigation]);
export default {
  components: {
    ProductItem,
    Swiper, SwiperSlide
  },
  data() {
    return {
      breakpoints: {
        1366.98: {
          slidesPerView: 5,
        },
        991.98: {
          slidesPerView: 4,
        },
        767.98: {
          slidesPerView: 3,
        },
        567.98: {
          slidesPerView: 'auto',
        },
        479.98: {
          slidesPerView: 'auto',
        },
        0: {
          slidesPerView: 'auto',
          spaceBetween: 10,
        },
      },
      navigation: {
        nextEl: ".popular-products__left",
        prevEl: ".popular-products__right",
      },
    }
  },
  computed: {
    ...mapState(['popularProducts']),
  },
  methods: {
    ...mapActions(['fetchHomePage'])
  }
}
</script>

<style lang="scss">
.popular-products {
  padding: 0 0 120px 0;
  @media (max-width: $tablet + px) {
    padding: 0 0 90px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 0 0 60px 0;
  }

  &__container {
    max-width: 1340px;
    box-sizing: content-box;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
    @media (max-width: $pc + px) {
      max-width: 1100px;
    }
    @media (max-width: $tablet + px) {
      max-width: none;
      padding: 0 0 0 16px;
    }
  }

  &__swiper{
    @media (max-width: $tablet + px) {
      padding: 0 16px 0 0!important;
    }
    .swiper-slide{
      height: auto;

      @media (max-width: $mobile + px) {
        min-width: 260px;
        width: 260px;
      }
      @media (max-width: $mobileSmall + px) {
        min-width: 244px;
        width: 244px;
      }
    }
  }

  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 46px 0;
    gap: 0 20px;
    @media (max-width: $tablet + px) {
      margin: 0 0 40.5px 0;
    }
    @media (max-width: $mobile + px) {
      padding: 0 16px 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      align-items: flex-end;
      margin: 0 0 35px 0;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    gap: 0 8px;
  }
}
</style>