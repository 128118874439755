<template>
  <div class="banner">
    <div class="banner__container _container">
      <swiper
        class="banner__swiper"
        :effect="'fade'"
        :speed="400"
        :pagination="{ clickable: true }"
      >
        <swiper-slide v-for="(slide, idx) in slider" :key="idx">
          <a :href="slide.url" class="banner__content">
            <div class="banner__info">
              <h1 class="banner__title">{{ slide.title }}</h1>
              <div class="banner__text" v-if="slide.content">{{ slide.content }}</div>
              <button
              v-if="idx === 0"
                @click.prevent="isOpenModal = true"
                class="banner__btn main-btn"
              >
                {{ $t("submitApp") }}
              </button>
            </div>
            <div class="banner__image">
              <img class="banner__image-desctop" :src="cdn + slide.image" alt="" />
              <img class="banner__image-mobile" :src="cdn + slide.image_mobile" alt="" />
            </div>
          </a>
        </swiper-slide>
      </swiper>
    </div>
    <ModalComponent :is-open="isOpenModal" @close="isOpenModal = false">
      <div class="banner__request request">
        <div class="request__titles">
          <h1 class="request__title form-title">{{ $t("submitApp1") }}</h1>
        </div>

        <VeeForm @submit="onSubmit" class="request__form">
          <div class="request__items">
            <div class="request__item">
              <VeeField
                name="name"
                rules="required"
                type="text"
                class="request__input main-input"
                :placeholder="$t('placeholder.name')"
                v-model="form.name"
              />
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="name"
                ></VeeErrorMessage>
              </transition>
            </div>
            <div class="request__item">
              <VeeField
                name="city"
                rules="required"
                type="text"
                class="request__input main-input"
                :placeholder="$t('placeholder.city1')"
                v-model="form.city"
              />
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="name"
                ></VeeErrorMessage>
              </transition>
            </div>
            <div class="request__item">
              <div class="counry">
                <div class="counry-selected" @click="tooglelang = !tooglelang">
                  <img :src="require('../../assets/images/langs/'+selectedLangs.img)" alt="flau" />
                  {{ selectedLangs.initial }}
                  <p style="padding-left: 10px;">{{ selectedLangs.phone }}</p>                    
                </div>
                <div class="counry-list" v-if="tooglelang">
                  <template v-for="item in langs" :key="item.id">
                    <div class="counry-list-item" @click="selectedLangs = item, tooglelang = false, form.phone_number = ''" v-if="item.id !== selectedLangs.id" >
                      <img :src="require('../../assets/images/langs/'+item.img)" alt="flau" />
                      {{ item.initial }}
                    </div>
                  </template>
                </div>
              </div>
              <VeeField
                type="tel"
                name="phone_number"
                rules="required"
                :maxlength="selectedLangs.id !== 1 ? 14 : 13"
                class="registration__input main-input phonemask"
                v-mask="selectedLangs.id !== 1 ? '(000) 000 0000' : '(000) 000-000'"
                :placeholder="selectedLangs.id !== 1 ? '(XXX) XXX XXXX' : '(XXX) XXX-XXX'"
                v-model="form.phone_number"
              />
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="phone"
                ></VeeErrorMessage>
              </transition>
            </div>
            <div class="request__item">
              <VeeField
                name="email"
                rules="required|email"
                type="email"
                class="request__input main-input"
                placeholder="E-mail*"
                v-model="form.email"
              />
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="email"
                ></VeeErrorMessage>
              </transition>
            </div>
            <div class="request__item">
              <VeeField
                name="organization"
                rules="required"
                type="text"
                class="request__input main-input"
                :placeholder="$t('placeholder.organization')"
                v-model="form.organization"
              />
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="name"
                ></VeeErrorMessage>
              </transition>
            </div>
            <div class="request__item">
              <VeeField
                name="text"
                type="text"
                v-model="form.text"
                v-slot="{ field }"
              >
                <textarea
                  class="request__input main-input"
                  :placeholder="$t('placeholder.comment')"
                  v-bind="field"
                  name="message"
                />
              </VeeField>
              <transition name="error">
                <VeeErrorMessage
                  class="error-message"
                  name="message"
                ></VeeErrorMessage>
              </transition>
            </div>
          </div>
          <button class="request__btn main-btn" type="submit">
            {{ $t("buttons.send") }}
          </button>
          <div class="request__accept">
            <VeeField
              name="privacy"
              value="accept"
              rules="required"
              type="checkbox"
              id="accept"
              class="request__checkbox main-checkbox"
            />
            <label
              for="accept"
              class="request__small-text"
              v-html="$t('uslovie')"
            >
            </label>
          </div>
          <transition name="error">
            <VeeErrorMessage
              class="error-message"
              name="privacy"
            ></VeeErrorMessage>
          </transition>
        </VeeForm>
      </div>
    </ModalComponent>
  </div>
</template>


<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import SwiperCore, { Pagination, EffectFade } from "swiper";
import { mapActions, mapState } from "vuex";
import ModalComponent from "@/components/ModalComponent";
import axios from "axios";
import { useToast } from "vue-toastification";

SwiperCore.use([Pagination, EffectFade]);
export default {
  components: {
    ModalComponent,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isOpenModal: false,
      form: {
        name: "",
        email: "",
        text: "",
        phone_number: "",
      },
      tooglelang: false,
      langs: [
        {
          id: 0,
          initial: "KZ",
          img: "kz.png",
          phone: "+7 "
        },
        {
          id: 1,
          initial: "KG",
          img: "kyr.png",
          phone: "+996 "
        },
        {
          id: 2,
          initial: "RU",
          img: "ru.png",
          phone: "+7 "
        },
      ],
      selectedLangs: {
          id: 0,
          initial: "KZ",
          img: "kz.png",
          phone: "+7 "
        }
    };
  },
  computed: {
    ...mapState(["slider", "cdn"]),
  },
  methods: {
    onSubmit() {
      this.form.phone_number = this.selectedLangs.phone + this.form.phone_number
      axios
        .post("feedback-collaboration", this.form)
        .then((response) => {
          this.isOpenModal = false;
          this.form = {
            name: "",
            email: "",
            phone_number: "",
            text: "",
          };
          this.toast.success(this.$t('zaysuc'))
        })
        .catch((errors) => {
          this.toast.warning(this.$t('zaysuc1'))
        });
    },
  },
};
</script>

<style lang="scss">
.banner {
  padding: 27px 0 120px 0;

  @media (max-width: $tablet + px) {
    padding: 23.5px 0 90px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 20px 0 50px 0;
  }

  &__swiper {
    height: 460px;

    .swiper-slide {
      position: relative;
      background: linear-gradient(
        96.45deg,
        #d81921 3.81%,
        #f98488 77.93%,
        #ef9a9e 90.21%
      );
      border-radius: 10px;
      overflow: hidden;
    }

    .swiper-pagination {
      text-align: left;
      left: 50px !important;
      bottom: 20px !important;
      @media (max-width: $tablet + px) {
        left: 35px !important;
      }
      @media (max-width: $mobile + px) {
        bottom: 25px !important;
      }
      @media (max-width: $mobileSmall + px) {
        left: 15px !important;
        bottom: 10px !important;
      }
    }

    .swiper-pagination-bullet {
      width: 28px;
      height: 5px;
      background: #757575;
      border-radius: 3px;
      opacity: 1 !important;

      &-active {
        background: #ffffff;
      }
    }
    @media (max-width: $pc + px) {
      max-height: 380px;
      height: 33vw;
    }
    @media (max-width: $mobileSmall + px) {
      height: 122vw;
      max-height: none;
    }
  }

  &__content {
    overflow: hidden;
    height: 100%;
    @media (min-width: $mobile + px) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__info {
    box-sizing: content-box;
    flex: 0 1 622px;
    display: flex;
    z-index: 1;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 0 46px;
    @media (max-width: $tablet + px) {
      flex: 0 1 550px;
      padding: 54px 35px;
      height: auto;
    }
    @media (max-width: $mobile + px) {
      padding: 35px 35px 0;
    }
    @media (max-width: $mobileSmall + px) {
      padding: 20px 20px 0;
      margin: 0 0 33px 0;
    }
  }

  &__title {
    color: #ffffff;
    font-weight: 600;
    font-size: min(max(24px, calc(1.75rem + ((1vw - 3.2px) * 1.25))), 40px);
    margin: 0 0 5px;
    @media (max-width: $tablet + px) {
    }
    @media (max-width: $mobileSmall + px) {
    }
  }

  &__text {
    color: #ffffff;
    font-weight: 600;
    font-size: min(max(24px, calc(1.75rem + ((1vw - 3.2px) * 1.25))), 40px);
    margin: 0 0 12px;
    @media (max-width: $tablet + px) {
    }
    @media (max-width: $mobileSmall + px) {
    }
  }

  &__btn {
    padding: 0px 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    background: #ffffff !important;
    color: #d81921 !important;
    border: none !important;
    margin-bottom: -220px;
    @media (max-width: 1375px) {
      margin-bottom: -150px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px !important;
      height: 40px !important;
      margin-bottom: -740px;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-desctop {
      display: block;
    }
    &-mobile {
      display: none;
    }
    @media screen and (max-width: $mobileSmall + px) {
      &-desctop {
        display: none;
      }
      &-mobile {
        display: block;
      }
    }    
  }
}

.request {
  @media (min-width: $mobile + px) {
    padding: 65px 100px 100px;
  }
  &__titles {
    max-width: 260px;
    text-align: center;
    margin: 0 auto 37px;
  }

  &__title {
    margin: 0 0 11px 0;
  }

  &__second-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }

  &__form {
    max-width: 360px;
    margin: 0 auto;
  }

  &__items {
    margin: 0 0 22px 0;
  }

  &__item {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #77757F;
    padding: 0px;
    .counry {
      position: relative;
      cursor: pointer;
      &-selected {        
        display: flex;
        align-items: center;
        gap: 5px;
        padding-left: 10px;
      }
      &-list {
        position: absolute;
        display: flex;
        flex-direction: column;
        padding-top: 5px;
        gap: 5px;
        background: white;
        left: 10px;
        top: 100%;
        &-item {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
      img {
        width: 15px;
        height: 10px;
      }
    }
    input {
      border: none;
    }
    &:not(:last-child) {
      margin: 0 0 17px 0;
    }
  }

  &__btn {
    width: 100%;
    margin: 0 0 28px 0;
  }

  &__accept {
    display: flex;
    align-items: flex-start;
    gap: 0 9px;
  }

  &__checkbox {
    min-height: 16px;
    min-width: 16px;
    width: 16px;
    height: 16px;

    &:after {
      background: url("../../assets/icons/shopping-cart/check.svg") center
        no-repeat;
    }
  }

  &__small-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}
.phonemask {
  padding-left: 5px !important;
}
</style>