<template>
  <div class="special-offers" v-if="fundsSale.length">
    <div class="special-offers__container _container">
      <div class="special-offers__top">
        <h2 class="special-offers__title main-title">
          {{ $t("saleProducts") }}
        </h2>
        <div class="special-offers__navigation">
          <div class="special-offers__right navigation-next">
            <span
              ><img src="../../assets/icons/swiper/arrow.svg" alt=""
            /></span>
          </div>
          <div class="special-offers__left navigation-prev">
            <span
              ><img src="../../assets/icons/swiper/arrow.svg" alt=""
            /></span>
          </div>
        </div>
      </div>

      <swiper
        :modules="modules"
        :breakpoints="breakpoints"
        :slides-per-view="5"
        :speed="650"
        :space-between="20"
        :navigation="navigation"
        class="special-offers__swiper"
      >
        <swiper-slide v-for="(product, item) in fundsSale" :key="item">
          <ProductItem
            @change="fetchHomePage"
            :product="product"
            :sale="true"
          ></ProductItem>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import ProductItem from "@/components/ProductItem";
import SwiperCore, { Navigation } from "swiper";
import { mapActions, mapState } from "vuex";

SwiperCore.use([Navigation]);
export default {
  components: {
    ProductItem,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      breakpoints: {
        1366.98: {
          slidesPerView: 5,
        },
        991.98: {
          slidesPerView: 4,
        },
        767.98: {
          slidesPerView: 3,
        },
        567.98: {
          slidesPerView: 2.5,
          spaceBetween: 10,
        },
        479.98: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        0: {
          slidesPerView: "auto",
          spaceBetween: 10,
        },
      },
      navigation: {
        nextEl: ".special-offers__left",
        prevEl: ".special-offers__right",
      },
    };
  },
  computed: {
    ...mapState(["fundsSale"]),
  },
  methods: {
    ...mapActions(["fetchHomePage"]),
  },
};
</script>

<style lang="scss">
.special-offers {
  padding: 0 0 120px 0;
  @media (max-width: $tablet + px) {
    padding: 0 0 90px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 0 0 60px 0;
  }

  &__container {
    max-width: 1340px;
    box-sizing: content-box;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
    @media (max-width: $pc + px) {
      max-width: 1100px;
    }
    @media (max-width: $tablet + px) {
      max-width: none;
      padding: 0 0 0 16px;
    }
  }

  &__swiper {
    @media (max-width: $tablet + px) {
      padding: 0 16px 0 0;
    }
    .swiper-slide {
      height: auto;

      @media (max-width: $mobile + px) {
        min-width: 260px;
        width: 260px;
      }
      @media (max-width: $mobileSmall + px) {
        min-width: 244px;
        width: 244px;
      }
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 46px 0;
    gap: 0 20px;
    @media (max-width: $tablet + px) {
      margin: 0 0 40.5px 0;
    }
    @media (max-width: $mobile + px) {
      padding: 0 16px 0 0;
    }
    @media (max-width: $mobileSmall + px) {
      align-items: flex-end;
      margin: 0 0 35px 0;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    gap: 0 8px;
  }
}
</style>