<template>
  <MainBanner></MainBanner>
  <MainServices></MainServices>
  <SaleOffers></SaleOffers>
  <PopularProducts></PopularProducts>
  <SpecialOffers></SpecialOffers>
  <MainNews></MainNews>
  <MainBrands></MainBrands>
</template>

<script>
import MainBanner from "@/components/home/MainBanner";
import MainBrands from "@/components/home/MainBrands";
import MainServices from "@/components/home/MainServices";
import MainNews from "@/components/home/MainNews";
import PopularProducts from "@/components/home/PopularProducts";
import SpecialOffers from "@/components/home/ SpecialOffers";
import { mapActions } from "vuex";
import SaleOffers from "../components/home/SaleOffers.vue";

export default {
  components: {
    SpecialOffers,
    PopularProducts,
    MainNews,
    MainServices,
    MainBrands,
    MainBanner,
    SaleOffers,
  },
  created() {
    this.fetchHomePage();
    // this.fetchContacts()
  },
  methods: {
    ...mapActions([
      "fetchHomePage",
      "fetchContacts",
      "checkAuth",
    ]),
  },
  mounted() {
    this.checkAuth();
  },
};
</script>

<style lang="scss" scoped>
</style>