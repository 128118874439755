<template>
  <div class="categories">
    <div class="categories__container _container">
      <div class="categories__items">
        <div
          class="categories__item"
          v-for="(category, i) in categories"
          :key="i"
          :class="`categories__item-1`"
        >
          <div
            @click="selectCategotry(category)"
            class="categories__wrapper"
          >
            <div class="categories__name">{{ category.title }}</div>
            <div class="categories__image" :class="`categories__image-1`">
              <img :src="cdn + category.image" alt="" />
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      categories: "homeCategories",
      cdn: "cdn",
    }),
  },
  methods: {
    ...mapMutations(["SET_CATEGORY", "SET_CATEGORY1"]),
    selectCategotry(category) {
      this.SET_CATEGORY1(category.id);
      this.SET_CATEGORY(category.id);
      this.$router.push('/catalog/'+category.slug)
    },
  },
};
</script>

<style lang="scss">
.categories {
  padding: 0px 0 120px 0;
  @media (max-width: $tablet + px) {
    padding: 0px 0 90px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 0px 0 60px 0;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: 30px;
    @media (max-width: $tablet + px) {
      grid-template-columns: calc(50% - 5px) calc(50% - 5px);
      grid-template-rows: repeat(3, 180px);
      row-gap: 25px;
    }
    @media (max-width: $mobile + px) {
      grid-gap: 10px;
    }
    @media (max-width: $mobileSmall + px) {
      grid-template-rows: 145px 145px 145px;
      row-gap: 20px;
    }
  }

  &__item {
    cursor: pointer;
    position: relative;
    background: #ececec;
    width: 32%;
    height: 196px;
    border-radius: 10px;
    overflow: hidden;
    padding: 0 0 35px 25px;
    @media (max-width: $tablet + px) {
      height: 180px;
    }
    @media (max-width: $mobileSmall + px) {
      padding: 0 8px 23px;
      width: 48%;
      height: 145px;
    }

    &-1 {
      .categories__name {
        max-width: 292px;
      }
    }

    &-3 {
      grid-column: 3;
      grid-row: 1 / 3;
      padding: 59px 0 0 25px;
      @media (max-width: $tablet + px) {
        grid-column: 1 / 3;
        grid-row: 3;
      }

      .categories__wrapper {
        align-items: flex-start;
      }
    }
  }

  &__wrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }

  &__name {
    font-weight: 600;
    font-size: 24px;
    line-height: 120.84%;
    max-width: 225px;
    @media (max-width: $tablet + px) {
      font-size: 20px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 15px;
    }
  }

  &__image {
    width: auto;
    height: 130px;
    position: absolute;
    top: 0;
    right: 0;

    &-1 {
      top: 23px;

      img {
        max-width: 188px;
        object-fit: contain;
        height: 130px;
      }

      @media (max-width: $pc + px) {
        top: 12px;
        img {
          width: 138px;
          height: 95px;
        }
      }
      @media (max-width: $mobile + px) {
        img {
          width: 96px;
          height: 68px;
        }
      }
    }

    &-2 {
      top: -16px;

      img {
        width: 260px;
        height: 174px;
        @media (max-width: $pc + px) {
          width: 230px;
          height: 143px;
        }
      }

      @media (max-width: $mobileSmall + px) {
        top: 0;
        img {
          width: 126px;
          height: 86px;
        }
      }
    }

    &-3 {
      top: 83px;

      img {
        width: 235px;
        height: 256px;
      }

      @media (max-width: $pc + px) {
        top: 110px;
        img {
          width: 205px;
          height: 226px;
        }
      }
      @media (max-width: $tablet + px) {
        top: 5px;
        right: 23px;
        img {
          width: 128px;
          height: 172px;
        }
      }
      @media (max-width: $mobileSmall + px) {
        top: -5px;
      }
    }

    &-4 {
      top: 27px;

      img {
        width: 180px;
        height: 121px;
        @media (max-width: $pc + px) {
          width: 150px;
          height: 91px;
        }
      }

      @media (max-width: $mobileSmall + px) {
        top: 12px;
        right: 4px;
        img {
          width: 123px;
          height: 68px;
        }
      }
    }

    &-5 {
      top: 30px;

      img {
        width: 228px;
        height: 148px;
      }

      @media (max-width: $pc + px) {
        top: 20px;
        img {
          width: 198px;
          height: 118px;
        }
      }
      @media (max-width: $mobileSmall + px) {
        top: 0;
        img {
          width: 120px;
          height: 78px;
        }
      }
    }
  }
}
</style>