<template>
  <transition name="fade">
    <div class="modal" v-if="isOpen" @click="logModal($event)">
      <div class="modal__wrapper">
        <div class="modal__icon" @click="closeModal">
          <img src="../assets/icons/header/close.svg" alt="" />
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
    },
  },

  methods: {
    closeModal() {
      this.$emit("close", false);
    },
    logModal(e) {
      const div = document.querySelector(".modal__wrapper");
      const withinBoundaries = e.composedPath().includes(div);

      if (!withinBoundaries) {
        this.$emit("close", false);
      }
    },
  },

  watch: {
    isOpen: function () {
      if (this.isOpen) {
        window.scroll(0, 0);
        document.body.style.overflow = "hidden";
      } else document.body.style.overflow = "auto";
    },
    "$route.path": function () {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);

  &__wrapper {
    overflow-y: auto;
    position: relative;
    background: #ffffff;
    @media (max-height: 700px) {
      height: 100%;
      .request {
        margin-top: 20px;
      }
    }
    @media (max-width: $mobile + px) {
      width: 100%;
      height: 100%;
      padding: 80px 16px;
    }
  }
  &__icon {
    position: absolute;
    top: 17.5px;
    right: 17.5px;
    img {
      cursor: pointer;
    }
  }
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms ease-out;
}

.fade-leave-to {
  opacity: 0;
}
</style>